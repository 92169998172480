import React from "react"
import PropTypes from "prop-types"

import "./layout.css"

const Layout = ({ children }) => {
  	return (
		<>
			<main>{children}</main>
			<footer>
				<div className="footer">
					<div className="footer-logos">
						<div className="footer-item footer-partners">
							OUR PARTNERS
						</div>

						<div className="footer-item">
							<img alt="" src="/logo-3keel.svg" height="70" />
						</div>
						
						<div className="footer-item" height="70">
							<img alt="" src="/logo_britishcoffee.svg" height="70" />
						</div>
						<div className="footer-item">
							<img alt="" src="/logo-eco.svg" height="70" />
						</div>
						<div className="footer-item">
							<img alt="" src="/logo-euro.svg" height="70" />
						</div>
						
					</div>

					<div className="footer-address">
						Podback Ltd.<br/>
						25 Moorgate, London<br/>
						EC2R 6AY<br/>
						UK
					</div>
				</div>
			</footer>
		</>
	)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
